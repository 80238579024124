import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import TermsScreen from "../views/TermsScreen.vue";
import TempView from "../views/TempView.vue";
import DownlaodLinkView from "../views/DownlaodLinkView.vue";
import PaymentsView from "../views/PaymentsView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsScreen,
  },
  {
    path: "/download-link",
    name: "download-link",
    component: DownlaodLinkView,
  },
  {
    path: "/temp",
    name: "temp",
    component: TempView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    component: PaymentsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
  hash: false,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
