<template>
  <div class="CustomersContainer">
    <div class="title-container">
      <h3 class="title">הלקוחות שלנו</h3>
      <div class="vertical-line"></div>
    </div>
    <div class="customers">
      <CustomersListImges />
      <CustomersList />
    </div>
  </div>
</template>

<script>
import CustomersList from "./CustomersList.vue";
import CustomersListImges from "./CustomersListImges.vue";
export default {
  name: "Customers",
  components: {
    CustomersList,
    CustomersListImges
  }
};
</script>

<style scoped>
.CustomersContainer {
  height: 400px;
   display: flex;
   flex-direction: column;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 16px;
  z-index: 1;
}

.title {
  color: #2196f3;
  font-weight: 300;
}

.vertical-line {
  height: 32px;
  width: 3px;
  background: #2196f3;
  margin-right: 32px;
  margin-left: 16px;
  border-radius: 2px;
}

.customers {
    display: flex;
    flex-grow: 1;
}
</style>