<template>
  <div class="ControlScheduleContainer center">
    <div class="space"></div>
    <div class="img-content">
      <div class="device"></div>
      <div class="appointment1 center">
        <h3 class="customer">לקוח 1</h3>
      </div>
      <div class="appointment2 center">
        <h3 class="customer">לקוח 2</h3>
      </div>
    </div>
    <div class="desc-content center">
      <h2 class="title">שליטה ובקרה</h2>
      <h2
        class="content"
      >שליטה מלאה בלוז העסק, הרחבת שעות העבודה, שריון שעות פעילות, דחיית כל התורים, וחסימת יום עבודה</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "ControlSchedule"
};
</script>

<style scoped>
.ControlScheduleContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/empty-schedule.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.appointment1 {
  width: 83%;
  height: 40px;
  margin-top: 105px;
  position: absolute;
  top: 0;
  right: 1%;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: appointment1-animation 8s ease-in-out forwards infinite;
}

.appointment2 {
  width: 83%;
  height: 40px;
  margin-top: 188px;
  position: absolute;
  top: 0;
  right: 1%;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: appointment2-animation 8s ease-in-out forwards infinite;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.customer {
  color: white;
  font-size: 0.5rem;
  font-weight: 400;
}

@keyframes appointment1-animation {
  0% {
    margin-top: 105px;
  }
  10% {
    margin-top: 145px;
  }
  70% {
    margin-top: 145px;
  }
  80% {
    margin-top: 105px;
  }
  100% {
    margin-top: 105px;
  }
}

@keyframes appointment2-animation {
  0% {
    margin-top: 188px;
    opacity: 1;
  }
  10% {
    margin-top: 228px;
  }
  70% {
    margin-top: 228px;
  }
  80% {
    margin-top: 188px;
    opacity: 0;
  }
  100% {
    margin-top: 188px;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}

@media only screen and (min-width: 801px) {
  .ControlScheduleContainer {
    direction: rtl;
  }
}
</style>