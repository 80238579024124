<template>
  <div class="DownloadAppContainer">
    <div
      id="businessImageContainer"
      :style=" businessImage === 'applates' ? { backgroundSize: 'cover' } : { backgroundImage: `url(${require('@/assets/' + businessImage)})` } "
    >
      <div class="opacityLayer"></div>
      <div class="app-icon-name" @click="$router.push('/')">
        <div class="app-icon"></div>
        <h3 class="app-name">Applates</h3>
      </div>
    </div>
    <div id="blueClippedDivv" class="onlyPhone"></div>
    <div id="pinkClippedDivv" class="onlyPhone"></div>
    <div class="DetailsContainer">
      <div id="blueTriangleBottomLeft" class="onlyPhone"></div>
      <div id="pinkTriangleBottomLeft" class="onlyPhone"></div>

      <div class="businessNameContainer">
        <h3 class="businessName">{{ businessName }}</h3>
      </div>

      <div class="text-col center">
        <div class="typewriter">
          <h3 class="first-sent">{{firstSent}}</h3>
        </div>
        <div class="typewriter">
          <h3 class="second-sent">{{secondSent}}</h3>
        </div>
      </div>

      <div class="downloadButtonsContainer">
        <v-btn
          color="purple"
          rounded="xl"
          elevation="4"
          height="48"
          size="small"
          class="download-app-btn"
          prepend-icon="mdi-apple"
        >
          <a class="download-app-link" :href="appStoreLink" target="_blank">App Store</a>
        </v-btn>
        <v-btn
          color="purple"
          rounded="xl"
          elevation="4"
          height="48"
          size="small"
          class="download-app-btn"
          prepend-icon="mdi-android"
        >
          <a class="download-app-link" :href="playStoreLink" target="_blank">Play Store</a>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted } from "vue";

const router = useRouter();
const route = useRoute();

const businessKey = ref("");
const businessName = ref("");
const businessImage = ref("applates2_logo.png");
const firstSent = ref("אנחנו ננהל את העסק עבורכם");
const secondSent = ref("אתם תעשו את העבודה הטובה ביותר");

const appStoreLink = ref("https://apps.apple.com/il/app/applates/id1618283239");
const playStoreLink = ref(
  "https://play.google.com/store/apps/details?id=com.applates.applates2"
);
const custom = ref("0");

onMounted(async () => {
  businessKey.value = route.query.key ? route.query.key : "applates";
  businessName.value = route.query.name ? route.query.name : "applates";
  //   businessImage.value = route.query.image ? route.query.image : "applates";
  custom.value = route.query.custom ? route.query.custom : "0";

  console.log(businessKey.value, businessName.value, "bi", businessImage.value);

  if (businessKey.value !== "applates") {
    firstSent.value = "הלקוחות שלנו יקבלו את";
    secondSent.value = "השירות הטוב ביותר";
    // businessImage.value = await getBusinessMainImage();

    businessImage.value = `${businessKey.value}.jpg`;
    // if (businessKey.value == "WeFit") {
    //   businessImage.value = "wfc.jpg";
    // } else if (businessKey.value == "StudioHail") {
    //     businessImage.value = "StudioHail.jpg";
    // }

    // custom app
    if (custom.value == "1") {
      if (businessKey.value == "WeFit") {
        appStoreLink.value = "https://apps.apple.com/app/id6470758734";
        playStoreLink.value =
          "https://play.google.com/store/apps/details?id=com.applates.waedfitness";
      }
      if (businessKey.value == "KassemFarag") {
        appStoreLink.value =
          "https://apps.apple.com/il/app/k-f-barbershop/id6502860810";
        playStoreLink.value =
          "https://play.google.com/store/apps/details?id=com.applates.kassemfarag";
      }
      if (businessKey.value == "WonderWoman") {
        appStoreLink.value =
          "https://apps.apple.com/app/na-fitness/id6670217417";
        playStoreLink.value =
          "https://play.google.com/store/apps/details?id=com.applates.wonderwoman";
      }
    }
  } else {
    businessName.value = "Applates ";
    businessImage.value = "two_devices.png";
  }
});

const getBusinessMainImage = async date => {
  const response = await fetch(
    `https://www.applates.click/api/business/getBusinessMainImage/${businessKey.value}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  const data = await response.json();
  console.log("data", data);
  return data.result;
};
</script>

<style scoped>
.DownloadAppContainer {
  display: flex;
  flex-direction: column;
  height: 92vh;
}

.opacityLayer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 10vh;
  position: absolute;
  top: 0px;
  z-index: 1;
  background: linear-gradient(rgb(184, 183, 183, 0.2), rgb(184, 183, 183, 0.1));
  /* opacity: 0.2; */
}

.app-icon-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 10vh;
  position: absolute;
  top: 0px;
  z-index: 2;
}

.app-icon {
  width: 50px;
  height: 50px;
  background-image: url("../../assets/applates2_logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 16px;
}

.app-name {
  font-family: "Quintessential", sans-serif;
  color: #2196f3;
  letter-spacing: 1.7px;
  font-size: 1.4rem;
  font-weight: 500;
}

.center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
  position: relative;
}

.vspace {
  padding-top: 72px;
  background: #0a2540;
  /* background: #eceff1; */
}

.text-col {
  flex-direction: column;
  z-index: 1;
}

#businessImageContainer {
  /* position: absolute;
  top: 0px; */
  width: 100%;
  height: 350px;
  background-color: #0a2540;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 60%, 100% 0%, 0 0%);
  clip-path: polygon(0% 0%, 0% 100%, 100% 75%, 100% 0%, 0 0%);

  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
}

#blueClippedDivv {
  position: absolute;
  top: 262px;
  right: 0;
  width: 10%;
  height: 50px;
  background-color: #d2eafc;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 20%, 0% 100%, 100% 80%, 100% 0%, 0 20%);
}

#pinkClippedDivv {
  position: absolute;
  top: 300px;
  right: 0;
  width: 5%;
  height: 40px;
  background-color: pink;
  opacity: 0.7;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 16%, 0% 100%, 100% 84%, 100% 0%, 0 16%);
}

.DetailsContainer {
  /* background: green; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
}

.businessNameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.businessName {
  font-family: "Quintessential", sans-serif;
  color: #2196f3;
  letter-spacing: 1.8px;
  font-size: 1.5rem;
  font-weight: 500;
}

.typewriter {
  direction: rtl;
}

.first-sent {
  color: #2196f3;
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  border-left: 0.15em solid transparent;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  width: 0;
  animation: typing1 3.5s steps(40, end) 1s forwards,
    blink-caret 0.7s step-end 4.5s forwards;
}

.second-sent {
  color: #616161;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  border-left: 0.15em solid transparent;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  width: 0;
  animation: typing2 3.5s steps(41, end) 5.2s forwards,
    blink-caret2 0.7s step-end 9s forwards;
}

.downloadButtonsContainer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.download-app-btn {
  margin-left: 8px;
  padding: 0 24px;
}

.download-app-link {
  text-decoration: none;
  color: #ffffff;
}

.squareDiv {
  position: absolute;
  top: 0;
  width: 100%;
  height: 250px;
  background-color: #0a2540;
}

#blueTriangleBottomLeft {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid #d2eafc;
  border-right: 50px solid white;
}

#pinkTriangleBottomLeft {
  position: absolute;
  bottom: 0px;
  left: 25px;
  width: 0;
  height: 0;
  opacity: 0.7;
  border-bottom: 40px solid pink;
  border-right: 40px solid white;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes typing1 {
  0% {
    width: 0;
  }
  1% {
    width: 0;
    border-left: 0.15em solid orange;
  }
  99% {
    width: 100%;
    border-left: 0.15em solid orange;
  }
  100% {
    width: 100%;
  }
}

@keyframes typing2 {
  0% {
    width: 0;
    border-left: 0.15em solid transparent;
  }
  1% {
    width: 0;
    border-left: 0.15em solid orange;
  }
  99% {
    width: 100%;
    border-left: 0.15em solid orange;
  }
  100% {
    width: 100%;
    border-left: 0.15em solid transparent;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes blink-caret2 {
  from,
  to {
    border-color: orange;
  }
  50% {
    border-color: transparent;
  }
}

@media only screen and (max-width: 800px) {
  .onlyPhone {
    background: #0a2540;
  }
}
</style>