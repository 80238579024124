
<template>
  <div class="sign">
    <div class="animate-sig">
      <h3>Signature</h3>
    </div>
  </div>
</template>

<script >
export default {
  name: "signature"
};
</script>


<style scoped>
.sign {
  width: 125px;
  height: 85px;
}

/* Hide the signature so it doesn't "flash" prior to animating */
.animate-sig h3 {
  display: hidden;
  padding-top: 15px;
  color: #2196d3;
}

/* Style the signature. In this case with a large cursive font. 
       It's important that the text doesn't wrap or show any overflow
       for the animation we're going for. */
.animate-sig h3 {
  font-family: "Dancing Script", cursive;
  font-size: 200%;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
}

/* The following blocks animate the reveal of the signature one
       keystroke at a time. Note that we've included webkit animations
       to better support Safari and other Webkit based browsers. 
       Note that the lenth of text you're animating and the width in 
       this block are closely related. You may need to increase or 
       decrease the width here for your signature. */
.animate-sig h3:nth-child(1) {
  width: 5.4em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end) forwards infinite;
}
@keyframes type {
  0% {
    width: 0;
  }
  100% {
    border: none;
  }
}
@-webkit-keyframes type {
  0% {
    width: 0;
  }
  100% {
    border: none;
  }
}
</style>