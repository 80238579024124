<script setup>
 import BenefitItem from './BenefitItem.vue'; 
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      console.log('start animation');
      const benefit1 = document.querySelector(".benefit1");
      const benefit2 = document.querySelector(".benefit2");
      const benefit3 = document.querySelector(".benefit3");
      const benefit4 = document.querySelector(".benefit4");
      const benefit5 = document.querySelector(".benefit5");
      const benefit6 = document.querySelector(".benefit6");

      benefit1.className += ' benefit1Animation';
      benefit2.className += ' benefit2Animation';
      benefit3.className += ' benefit3Animation';
      benefit4.className += ' benefit4Animation';
      benefit5.className += ' benefit5Animation';
      benefit6.className += ' benefit6Animation';
    } 
    //  else if (!entry.isIntersecting) {
    //   console.log("remove animation");
    //   const benefit1 = document.querySelector(".benefit1");
    //   const benefit2 = document.querySelector(".benefit2");
    //   const benefit3 = document.querySelector(".benefit3");
    //   benefit1.classList.remove("benefit1Animation");
    //   benefit2.classList.remove("benefit2Animation");
    //   benefit3.classList.remove("benefit3Animation");
    // }
  });
});

document.addEventListener("DOMContentLoaded", function() {
  observer.observe(document.querySelector(".startAnimation"));
});
</script>

<template>
  <div class="BenefitsContainer">
    <div class="title-container">
      <h3 class="title">מה תקבלו</h3>
      <div class="vertical-line"></div>
    </div>
    <div class="benefits-container" >
        <div class="startAnimation"></div>
         <div class="benefit benefit1">
            <BenefitItem icon='fa-solid fa-play' content='שליטה בעסק מכל מקום כל הזמן' />
         </div>
         <div class="benefit benefit2">
            <BenefitItem icon='fa-solid fa-play' content='ניהול הלו"ז עלינו, אתם תתמקדו בעבודה המקצועית ומתן שירות עם חיוך' />
         </div>
         <div class="benefit benefit3">
            <BenefitItem icon='fa-solid fa-play' content='מיקוד בהרחבת הידע, היכולות, ולהביא את הטוב ביותר ללקוחות'/>
         </div>
         <div class="benefit benefit4">
            <BenefitItem icon='fa-sharp fa-solid fa-play' content='אין דאגות עסקיות, קבלו את חייכם הפרטיים בחזרה' />
         </div>
         <div class="benefit benefit5">
            <BenefitItem icon='fa-solid fa-play' content='הכי חשוב מענה ללקוחות בעלי מוגבלויות בעיקר ליקויי שמיעה ודיבור' />
         </div>
          <div class="benefit benefit6">
            <BenefitItem icon='fa-solid fa-play' content='לקוחות מרוצים ושבעי רצון' />
         </div>
         
        <!-- <div class="benefit1">
            <BenefitItem icon='fa-solid fa-gamepad' content='שליטה בעסק מכל מקום כל הזמן' />
        </div> -->
      <!-- <BenefitItem icon='fa-solid fa-house fa-sm' content='ניהול הלו"ז עלינו, אתם תעבדו עם חיוך' />
      <BenefitItem icon='fa-solid fa-gears' content='אין דאגות עסקיות, קבלו את חייכם הפרטיים בחזרה' />
      <BenefitItem icon='fa-solid fa-gears' content='מיקוד בהרחבת הידע, היכולות, ולהביא את הטוב ביותר ללקוחות' />
      <BenefitItem icon='fa-solid fa-gears' content='הכי חשוב מענה ללקוחות בעלי מוגבלויות בעיקר ליקויי שמיעה ודיבור' /> -->
    </div>
  </div>
</template>

<style scoped>
.BenefitsContainer {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 32px;
  z-index: 1;
}

.title {
  color: #2196f3;
  font-weight: 300;
}

.vertical-line {
  height: 32px;
  width: 3px;
  background: #2196f3;
  margin-right: 32px;
  margin-left: 16px;
  border-radius: 2px;
}

.benefits-container { 
    height: 320px;
    overflow: hidden;
    padding-right: 8px;
    padding-left: 16px;
}

.startAnimation {
  height: 0px;
}

.benefit {
background: white;
}

.benefit1 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 320px;
    border-radius: 16px 16px 0 0px;
    
}

.benefit2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
     top: 270px;
}

.benefit3 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 220px;
}

.benefit4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 170px; 
}

.benefit5 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 120px; 
}

.benefit6 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 70px; 
}

.benefit1Animation {
animation: benefit1-animation 1.0s ease-in-out forwards;
}

.benefit2Animation {
animation: benefit1-animation 1.0s ease-in-out 1.25s forwards;
}

.benefit3Animation {
animation: benefit1-animation .8s ease-in-out 2.5s forwards;
}

.benefit4Animation {
animation: benefit1-animation .6s ease-in-out 3.55s forwards;
}

.benefit5Animation {
animation: benefit1-animation .6s ease-in-out 4.4s forwards;
}

.benefit6Animation {
animation: benefit1-animation .6s ease-in-out 5s forwards;
}

@keyframes benefit1-animation {
    100% {
        top: 0px;
    }
}
</style>