<template>
  <div class="main center">
    <div class="img-content">
      <div class="device"></div>

      <div class="business-img1"></div>
      <div class="business-img2"></div>
      <div class="business-img3"></div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.main {
  flex-grow: 1;
}

.img-content {
  width: 240px;
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.device {
  width: 240px;
  height: 320px;
  background-image: url("../../assets/fitness-screen-dark.png");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  box-shadow: 2px 4px #616161;
}

.business-img1 {
  width: 240px;
  height: 250px;
  position: absolute;
  top: 0;
  right: 0px;
  margin-right: 0px;
  border-radius: 10px 10px 0px 0px;
  background-image: url("../../assets/fitness-screen-dark.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  animation: business-img1-animation 10s ease-in-out forwards infinite;
}

.business-img2 {
  width: 240px;
  height: 250px;
  position: absolute;
  top: 0;
  right: 0px;
  margin-right: -240px;
  border-radius: 10px 10px 0px 0px;
  background-image: url("../../assets/barber-screen-dark.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  animation: business-img2-animation 10s ease-in-out forwards infinite;
}

.business-img3 {
  width: 240px;
  height: 250px;
  position: absolute;
  top: 0;
  right: 0px;
  margin-right: -240px;
  border-radius: 10px 10px 0px 0px;
  background-image: url("../../assets/beauty-screen-dark.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  animation: business-img3-animation 10s ease-in-out forwards infinite;
}

@keyframes business-img1-animation {
  0% {
    margin-right: 0px;
  }
  25% {
    margin-right: 0px;
  }
  30% {
    margin-right: 240px;
    opacity: 1;
  }
  31% {
    margin-right: 240px;
    opacity: 0;
  }
  32% {
    margin-right: -240px;
    opacity: 0;
  }
  33% {
    margin-right: -240px;
    opacity: 1;
  }
  85% {
    margin-right: -240px;
    opacity: 1;
  }
  90% {
    margin-right: 0px;
    opacity: 1;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes business-img2-animation {
  0% {
    margin-right: -240px;
  }
  25% {
    margin-right: -240px;
  }
  30% {
    margin-right: 0px;
    opacity: 1;
  }
  55% {
    margin-right: 0px;
    opacity: 1;
  }
  60% {
    margin-right: 240px;
    opacity: 1;
  }
  61% {
    margin-right: 240px;
    opacity: 0;
  }
  62% {
    margin-right: -240px;
    opacity: 0;
  }
  63% {
    margin-right: -240px;
    opacity: 1;
  }
  100% {
    margin-right: -240px;
    opacity: 1;
  }
}

@keyframes business-img3-animation {
  0% {
    margin-right: -240px;
    opacity: 1;
  }
  25% {
    margin-right: -240px;
  }
  30% {
    margin-right: -240px;
  }
  55% {
    margin-right: -240px;
  }
  60% {
    margin-right: 0px;
    opacity: 1;
  }
  85% {
    margin-right: 0px;
     opacity: 1;
  }
  90% {
    margin-right: 240px;
    opacity: 1;
  }
  91% {
    margin-right: 240px;
    opacity: 0;
  }
  92% {
    margin-right: -240px;
    opacity: 0;
  }
  93% {
    margin-right: -240px;
    opacity: 1;
  }
  100% {
    margin-right: -240px;
    opacity: 1;
  }
}
</style>