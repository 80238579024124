<template>
  <div class="WaitingListContainer center">
    <div class="desc-content center">
      <h2 class="title">רשימת המתנה</h2>
      <h2
        class="content"
      >הלו״ז מלא? לקוחות יצטרפו לרשימת המתנה בשעות המתאימות להם, ויקבלו עדכונים כשמתפנה מקום</h2>
    </div>
    <div class="img-content">
      <div class="device"></div>

      <div class="appointment1 center">
        <h3 class="customer">לקוח 1</h3>
      </div>
      <div class="appointment2 center">
        <h3 class="customer">לקוח 2</h3>
      </div>

      <div class="notification-modal center">
        <div class="notification-content">
          <h3>רשימת המתנה</h3>
          <h4>התפנה מקום בקבוצת אימון אירובי ב- 06/04 בשעה 11:30</h4>
        </div>
        <div class="notification-icon center"></div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "Notifications"
};
</script>

<style scoped>
.WaitingListContainer {
  height: 400px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/empty-schedule.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.notification-modal {
  display: flex;
  width: 92%;
  height: 48px;
  margin-bottom: 240px;
  background: #414141;
  border: 1px solid #414141;
  position: absolute;
  bottom: 0;
  right: 8px;
  border-radius: 10px;
  padding: 0 10px;
  animation: modal-animation 8s ease-in-out forwards infinite;
}

.notification-icon {
  width: 36px;
  height: 32px;
  background-image: url("../../assets/applates2_logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.notification-content {
  flex-grow: 1;
  height: 40px;
  padding: 0 8px;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.notification-content h3 {
  color: #ffffff;
  font-size: 0.6rem;
}

.notification-content h4 {
  color: #ffffff;
  font-size: 0.5rem;
  text-align: end;
  font-weight: 400;
}

.appointment1 {
  width: 82%;
  height: 40px;
  margin-top: 105px;
  position: absolute;
  top: 0;
  right: 2px;
  /* margin-left: 20px; */
  background: #2196f3;
  border-radius: 4px;
}

.appointment2 {
  width: 82%;
  height: 40px;
  margin-top: 188px;
  position: absolute;
  top: 0;
  right: 2px;
  background: #2196f3;
  border-radius: 4px;
  animation: appointment2-animation 8s ease-in-out forwards infinite;
}

.customer {
  color: white;
  font-size: 0.5rem;
  font-weight: 400;
}

.space {
  width: 32px;
  height: 300px;
}

@keyframes modal-animation {
  0% {
    margin-bottom: 300px;
  }
  10% {
    margin-bottom: 240px;
  }
  70% {
    margin-bottom: 240px;
  }
  80% {
    margin-bottom: 300px;
  }
  100% {
    margin-bottom: 300px;
  }
}

@keyframes appointment2-animation {
  0% {
    margin-bottom: 300px;
    opacity: 1;
  }
  10% {
    margin-bottom: 240px;
    opacity: 0;
  }
  70% {
    margin-bottom: 240px;
    opacity: 0;
  }
  80% {
    margin-bottom: 300px;
    opacity: 1;
  }
  100% {
    margin-bottom: 300px;
    opacity: 1;
  }
}

/* @media only screen and (max-width: 420px) {
  .appointment1 {
    width: 155px;
  }
} */

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}
</style>