<template>
    <div class="center-col ">
      <div class="app-icon-name">
        <div class="app-icon"></div>
        <h3 class="app-name">Applates</h3>
      </div>
      <div class="text-col center">
        <div class="typewriter">
          <h3 class="first-sent">אנחנו ננהל את העסק עבורכם</h3>
        </div>
        <div class="typewriter">
          <h3 class="second-sent">אתם תעשו את העבודה הטובה ביותר</h3>
        </div>
      </div>
    </div>
</template>
 
<script setup>

</script>

<style scoped>

.center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 400px;
}

.vspace {
  padding-top: 72px;
  background: #0a2540;
  /* background: #eceff1; */
}

.text-col {
  flex-direction: column;
}

.app-icon-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-icon {
  width: 110px;
  height: 110px;
  background-image: url("../../assets/applates2_logo.png");
  background-position: center;
  background-size: contain;
}

.app-name {
  font-family: "Quintessential", sans-serif;
  color: #2196f3;
  letter-spacing: 1.7px;
  font-size: 1.4rem;
  font-weight: 500;
}

.typewriter {
  direction: rtl;
}

.first-sent {
  color: #2196f3;
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  border-left: 0.15em solid transparent;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  width: 0; 
  animation: typing1 3.5s steps(40, end) 1s forwards, 
  blink-caret 0.7s step-end 4.5s forwards;
}

.second-sent {
  color: #ffffff; 
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  border-left: 0.15em solid transparent;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  width: 0; 
  animation: typing2 3.5s steps(41, end) 5.2s forwards,
    blink-caret2 0.7s step-end 9s forwards;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes typing1 {
  0% {
    width: 0;
  }
  1% {
    width: 0;
    border-left: 0.15em solid orange;
  }
  99% {
    width: 100%;
    border-left: 0.15em solid orange;
  }
  100% {
    width: 100%;
  }
}


@keyframes typing2 {
  0% {
    width: 0;
    border-left: 0.15em solid transparent;
  }
  1% {
    width: 0;
    border-left: 0.15em solid orange;
  }
  99% {
    width: 100%;
    border-left: 0.15em solid orange;
  }
  100% {
    width: 100%;
     border-left: 0.15em solid transparent;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes blink-caret2 {
  from,
  to {
    border-color: orange;
  }
  50% {
    border-color: transparent;
  }
}

@media only screen and (max-width: 800px) {
  .onlyPhone {
    background: #0a2540;
  }

  .second-sent {
  color: #ffffff; 
  }
}
</style>