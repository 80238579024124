<script setup>
import { ref } from "vue";

// document.querySelector(".square");

const activateSquareAnimation = ref(false);

// setTimeout(() => {
//     activateSquareAnimation.value = true;
// }, 1500);

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add("squareAnimation");
    }
  });
});

document.addEventListener("DOMContentLoaded", function() {
  console.log("DOMContentLoaded");
  observer.observe(document.querySelector(".square"));
});
</script>

<template>
  <div>
    <div class="divv div1"></div>
    <div class="divv div2">
      <!-- <div class="square"></div> -->
      <!-- <div class="square" :class="activateSquareAnimation ? 'squareAnimation' : ''"></div> -->
    </div>
    <div class="divv div3">
        <div class="square"></div>
    </div>
    <div class="divv div4"></div>
    <div class="divv div5"></div>
    <div class="divv div6"></div>
  </div>
</template>

<style scoped>
.divv {
  width: 100vw;
  height: 100vh;
}

.div1 {
  background: green;
}

.div2 {
  background: blue;
}

.div3 {
  background: red;
}

.div4 {
  background: yellow;
}

.div5 {
  background: whitesmoke;
}

.div6 {
  background: rebeccapurple;
}

.square {
  width: 100px;
  height: 100px;
  background: pink;
}

.squareAnimation {
  animation: square-animation 2s linear forwards;
}

@keyframes square-animation {
  100% {
    transform: translateX(100%);
  }
}
</style>