<template>
  <div class="MoreInfoContainer">
    <div class="title-container">
      <h3 class="title">מידע נוסף</h3>
      <div class="vertical-line"></div>
    </div>
    <div class="center">
      <div v-if="_displayMode == DisplayMode.MoreInfo" class="user-info mx-auto">
        <v-form @submit.prevent>
          <v-text-field
            class="text-input"
            :error-messages="nameErrorMessages"
            v-model="userName"
            label="שם מלא"
          ></v-text-field>
          <v-text-field
            class="text-input"
            :error-messages="businessErrorMessages"
            v-model="businessName"
            label="שם העסק"
          ></v-text-field>
          <v-text-field
            class="text-input"
            :error-messages="phoneErrorMessages"
            v-model="phoneNumber"
            label="נייד"
          ></v-text-field>
          <v-btn @click="submit" type="submit" block class="mt-2 submit-btn">שלח פרטים</v-btn>
        </v-form>
      </div>
      <div v-if="_displayMode == DisplayMode.Loading" class="loadingContainer">
        <v-progress-circular indeterminate color="#2196f3"></v-progress-circular>
        <h3>נא להמתין</h3>
      </div>
      <div v-if="_displayMode == DisplayMode.Success" class="successContainer">
        <h3>פרטיך התקבלו בהצלחה</h3>
        <h4>נצור איתך קשר בהקדם</h4>
      </div>
      <div v-if="_displayMode == DisplayMode.TooManyRequests" class="successContainer">
        <h4>שגיאה, שליחת הרבה בקשות</h4>
        <h4>נסה שנית מאוחר יותר</h4>
      </div>
    </div>
    <v-snackbar v-model="snackbar" color="deep-purple-accent-4" elevation="24">
      <h3 class="snackbarText">{{ snackbarText }}</h3>
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const DisplayMode = {
  MoreInfo: "MoreInfo",
  Loading: "Loading",
  Success: "Success",
  TooManyRequests: "TooManyRequests"
};

const _displayMode = ref(DisplayMode.MoreInfo);

const userName = ref("");
const businessName = ref("");
const phoneNumber = ref("");

const nameErrorMessages = ref("");
const businessErrorMessages = ref("");
const phoneErrorMessages = ref("");

const snackbar = ref(false);
const snackbarText = ref("שגיאה, נסה שנית מאוחר יותר");

const submit = async () => {
  console.log("submit", _displayMode);

  try {
    const errorMessage = "קלט לא חוקי";
    let valid = true;
    if (userName.value.trim().length < 5) {
      nameErrorMessages.value = errorMessage;
      valid = false;
    } else {
      nameErrorMessages.value = "";
    }

    if (businessName.value.trim().length < 5) {
      businessErrorMessages.value = errorMessage;
      valid = false;
    } else {
      businessErrorMessages.value = "";
    }

    if (
      phoneNumber.value.trim().length !== 10 ||
      !/^\d+$/.test(phoneNumber.value)
    ) {
      phoneErrorMessages.value = errorMessage;
      valid = false;
    } else {
      phoneErrorMessages.value = "";
    }

    if (valid) {
      _displayMode.value = DisplayMode.Loading;
      const date = getCurrentTime();
      if (canSendRequest(date)) {
        const result = await contactUs(date);
        // const result = "error";
        console.log("result", result);

        if (result === "successfully") {
          _displayMode.value = DisplayMode.Success;
        } else {
          _displayMode.value = DisplayMode.MoreInfo;
          snackbarText.value = "שגיאה, נסה שנית מאוחר יותר";
          snackbar.value = true;
        }
      } else {
        _displayMode.value = DisplayMode.TooManyRequests;
      }
    }
  } catch (err) {
    _displayMode.value = DisplayMode.MoreInfo;
    snackbarText.value = "שגיאה, נסה שנית מאוחר יותר";
    snackbar.value = true;
  }
};

const contactUs = async date => {
  const response = await fetch(
    `https://www.applates.click/api/business/contactUs`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        phonenumber: phoneNumber.value,
        name: userName.value,
        businessname: businessName.value,
        date: date
      })
    }
  );

  const data = await response.json();
  return data.result;
};

const getCurrentTime = () => {
  const currentDate = new Date();
  let day = +currentDate.getDate();
  let month = +(currentDate.getMonth() + 1);
  let year = currentDate.getFullYear();

  let dayStr = "" + day;
  if (day < 10) {
    dayStr = "0" + day;
  }

  let monthStr = "" + month;
  if (month < 10) {
    monthStr = "0" + month;
  }

  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  const timeFormat = `${dayStr}/${monthStr}/${year} - ${hours}:${minutes}`;
  return timeFormat;
};

const canSendRequest = (date) => {
  const attempts = localStorage.getItem(`${date}`);
  if (attempts && attempts > 3) {
    return false;
  } else {
    updateRequestAttemptsNumber(date);
    return true;
  }

  //localStorage.setItem('name', 'yousef');
  //console.log('name', localStorage.getItem('name'));
};

const updateRequestAttemptsNumber = (date) => {
  const attempts = localStorage.getItem(`${date}`);
  if (attempts) {
    localStorage.setItem(`${date}`, attempts + 1);
  } else {
    localStorage.setItem(`${date}`, 1);
  }
}
</script>

<style scoped>
.MoreInfoContainer {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 32px;
}

.title {
  color: #2196f3;
  font-weight: 300;
}

.vertical-line {
  height: 32px;
  width: 3px;
  background: #2196f3;
  margin-right: 32px;
  margin-left: 16px;
  border-radius: 2px;
}

.user-info {
  width: 80%;
}

.submit-btn {
  background: #2196f3;
  color: white;
}

.text-input {
  direction: rtl;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.loadingContainer h3 {
  color: #2196f3;
  padding: 16px;
  font-size: 1rem;
  font-weight: 300;
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.successContainer h3 {
  color: #2196f3;
  padding: 4px;
  font-size: 1.1rem;
  font-weight: 300;
}

.successContainer h4 {
  color: #616161;
  padding: 4px;
  font-size: 1rem;
  font-weight: 300;
}

.snackbarText {
  font-size: 0.9rem;
  font-weight: 300;
  text-align: center;
}

/* .v-text-field >>> .input {
    font-size: 20px;
    background: red;
    color: green;
  } */

/* .v-input >>> .v-label {
  font-size: 14px;
  font-weight: 500;
  color: #2196f3;
} */
</style>