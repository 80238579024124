<template>
  <div class="nav">
    <router-link class="link" to="/">
      <h3 class="app-name">Applates</h3>
    </router-link>
    <a href="https://applates-efb1a.firebaseapp.com/" target="_blank"><i class="fa-solid fa-house fa-sm" style="color: #2196f3;"></i></a>
  </div>
</template>
 
<script>
</script>

<style scoped>
.nav {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100vw; */
  background: #0a2540;
}
.app-name {
  font-family: "Quintessential", sans-serif;
  color: #2196f3;
  letter-spacing: 1.7px;
  font-size: 1.3rem;
  font-weight: 600;
}

.link {
    text-decoration: none;
}
</style>