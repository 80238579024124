<template>
  <div class="SubCardsContainer center">
    <div class="desc-content center">
      <h2 class="title">מנויים וכרטיסיות</h2>
      <h2 class="content">
        בקרה וניהול כרטיסיות
        <br />מעקב אחר מנויים
        <br />תזכורות לחידוש מנויים
      </h2>
    </div>
    <div class="img-content">
      <div class="device"></div>
      <div class="calender-box"></div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "SubCards"
};
</script>

<style scoped>
.SubCardsContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/calender.png");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.calender-box {
  width: 0px;
  height: 0px;
  margin-top: 174px;
  position: absolute;
  top: 0;
  left: 25px;
  opacity: 1;
  background: #2196f3;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  animation: calender-box-animation 10s ease-in-out forwards infinite;
}


.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.customer {
  color: white;
  font-size: 0.5rem;
  font-weight: 400;
}

@keyframes calender-box-animation {
  0% {
    width: 0px;
    height: 0px;
  }
  30% {
    width: 150px;
    height: 60px;
  }
  70% {
    width: 150px;
    height: 60px;
  }
  80% {
    width: 150px;
    height: 60px;
    opacity: 0;
  }
  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
}


@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}
</style>