<template>
  <div class="GroupAppointmentContainer center">
    <div class="space"></div>
    <div class="img-content">
      <div class="device"></div>
      <div class="overlay"></div>
      <div class="modal"></div>
    </div>
    <div class="desc-content center">
      <h2 class="title">ניהול קבוצות</h2>
      <h2 class="content">סטודיו לאימונים ינהל קבוצות לאימונים אישיים וקבוצתיים</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupAppointment"
};
</script>

<style scoped>
.GroupAppointmentContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/group-appointment.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.modal {
  width: 200px;
  height: 105px;
  margin-top: 195px;
  position: absolute;
  top: 0;
  border-radius: 10px 10px 0 0;
  background-image: url("../../assets/join-group-modal.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  animation: modal-animation 8s ease-in-out forwards infinite;
}

.overlay {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 0;
  border-radius: 10px 10px 0 0;
  background: black;
  opacity: 0;
  animation: overlay-animation 8s linear forwards infinite;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

@keyframes add-queue-modal-animation {
  0% {
    margin-top: 300px;
  }
  10% {
    margin-top: 120px;
  }
  70% {
    margin-top: 120px;
  }
  80% {
    margin-top: 300px;
  }
  100% {
    margin-top: 300px;
  }
}

@keyframes overlay-animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes modal-animation {
  0% {
    margin-top: 300px;
  }
  10% {
    margin-top: 195px;
  }
  70% {
    margin-top: 195px;
  }
  80% {
    margin-top: 300px;
  }
  100% {
    margin-top: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}

@media only screen and (min-width: 801px) {
  .GroupAppointmentContainer {
    direction: rtl;
  }
}
</style>