<template>
  <div class="main center">
    <div class="line"></div>
    <div class="center">
      <h3 class="allRights">אפלאטס, כל הזכויות שמורות ©2024</h3>
    </div>
    <div class="privacyTerms center">
      <h3>
        <router-link class="link" to="/terms">תנאי שימוש</router-link>
      </h3>
      <h3>
        <router-link class="link" to="/privacy">מדיניות הפרטיות</router-link>
      </h3>
      <h3>info@applates.info</h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main {
  width: 100%;
  height: 60px;
  flex-direction: column;
}

.line {
  height: 1px;
  width: 50vw;
  background: #616161;
  margin-bottom: 8px;
}

.privacyTerms {
  width: 400px;
}

.allRights {
  font-size: 14px;
  color: #616161;
  font-weight: 300;
}

.privacyTerms h3 {
  padding: 0 8px;
  font-size: 12px;
  text-decoration: underline;
  color: #2196f3;
}

.link {
  text-decoration: none;
}

a:visited {
  color: #2196f3;
}

a:active {
  color: #2196f3;
}
</style>