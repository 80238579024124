<template>
  <div class="whyApplatesContainer">
    <!-- <div id="rectangle" class="onlyPhone"></div>  -->
    <div class="title-container">
      <h3 class="title">למה אפלאטס</h3>
      <div class="vertical-line"></div>
    </div>
    <div class="grid-item CustomApp">
      <CustomApp />
    </div>
    <div class="grid-item ScheduleAppointment">
      <ScheduleAppointment />
    </div>
    <div class="grid-item GroupAppointment">
      <GroupAppointment />
    </div>
    <div class="grid-item Notifications">
      <Notifications />
    </div>
    <div class="grid-item ControlSchedule">
      <ControlSchedule />
    </div>
    <div class="grid-item SubCards">
      <SubCards />
    </div>
    <div class="grid-item BodyMeasurements">
      <BodyMeasurements />
    </div>
    <div class="grid-item HealthDeclaration">
      <HealthDeclaration />
    </div>
    <div class="grid-item WaitingList">
      <WaitingList />
    </div>
    <div class="grid-item NotificationCenter">
      <NotificationCenter />
    </div>
    <div class="grid-item Analytics">
      <Analytics />
    </div>
    <div class="grid-item Accessibility">
      <Accessibility />
    </div>

    <div id="clippedDivv" class="onlyPhone"></div>
    <div id="blueClippedDiv" class="onlyPhone"></div>
    <div id="pinkClippedDiv" class="onlyPhone"></div>
    <div id="blueTriangleTopLeft" class="onlyPhone"></div>
    <div id="pinkTriangleTopLeft" class="onlyPhone"></div>
  </div>
</template>

<script>
import ScheduleAppointment from "./ScheduleAppointment.vue";
import GroupAppointment from "./GroupAppointment.vue";
import Notifications from "./Notifications.vue";
import ControlSchedule from "./ControlSchedule.vue";
import SubCards from "./SubCards.vue";
import BodyMeasurements from "./BodyMeasurements.vue";
import Analytics from "./Analytics.vue";
import Accessibility from "./Accessibility.vue";
import WaitingList from "./WaitingList.vue";
import NotificationCenter from "./NotificationCenter.vue";
import HealthDeclaration from "./HealthDeclaration.vue";
import CustomApp from "./CustomApp.vue";

export default {
  name: "WhyApplates",
  components: {
    ScheduleAppointment,
    GroupAppointment,
    Notifications,
    ControlSchedule,
    SubCards,
    BodyMeasurements,
    Analytics,
    Accessibility,
    WaitingList,
    NotificationCenter,
    HealthDeclaration,
    CustomApp
  }
};
</script>

<style scoped>
.whyApplatesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 30px;
  grid-template-areas:
    /* "title-container title-container"
    "ScheduleAppointment CustomApp"
    "Notifications GroupAppointment"
    "SubCards ControlSchedule"
    "HealthDeclaration BodyMeasurements"
    "NotificationCenter WaitingList"
    "Accessibility Analytics"; */
    "title-container title-container"
    "GroupAppointment ScheduleAppointment"
    "ControlSchedule Notifications"
    "BodyMeasurements SubCards"
    "WaitingList HealthDeclaration"
    "Analytics NotificationCenter"
    "Accessibility CustomApp";
  position: relative;
}

.grid-item {
  height: 400px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;
  grid-area: title-container;
  z-index: 1;
}

.title {
  color: #2196f3;
  font-weight: 300;
}

.vertical-line {
  height: 32px;
  width: 3px;
  background: #2196f3;
  margin-right: 32px;
  margin-left: 16px;
  border-radius: 2px;
}

.ScheduleAppointment {
  grid-area: ScheduleAppointment;
  z-index: 1;
}

.GroupAppointment {
  grid-area: GroupAppointment;
}

.Notifications {
  grid-area: Notifications;
}

.ControlSchedule {
  grid-area: ControlSchedule;
}

.SubCards {
  grid-area: SubCards;
}

.BodyMeasurements {
  grid-area: BodyMeasurements;
}

.WaitingList {
  grid-area: WaitingList;
}

.NotificationCenter {
  grid-area: NotificationCenter;
}

.Analytics {
  grid-area: Analytics;
}

.Accessibility {
  grid-area: Accessibility;
}

.HealthDeclaration {
  grid-area: HealthDeclaration;
}

.CustomApp {
  grid-area: CustomApp;
}

#clippedDivv {
  position: absolute;
  top: -50px;
  width: 100%;
  height: 250px;
  /* background: green; */
  background: #f7f9fc;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 60%, 100% 0%, 0 0%);
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 0 25%);
}

#blueClippedDiv {
  position: absolute;
  top: -35px;
  left: 0;
  width: 15%;
  height: 50px;
  background-color: #d2eafc;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 20%, 0% 100%, 100% 80%, 100% 0%, 0 20%);
}

#pinkClippedDiv {
  position: absolute;
  top: -45px;
  left: 0;
  width: 8%;
  height: 40px;
  background-color: pink;
  opacity: 0.7;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 16%, 0% 100%, 100% 84%, 100% 0%, 0 16%);
}

#blueTriangleTopLeft {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 50px solid #d2eafc;
  border-right: 50px solid transparent;
}

#pinkTriangleTopLeft {
  position: absolute;
  bottom: -40px;
  left: 25px;
  width: 0;
  height: 0;
  opacity: 0.7;
  border-top: 40px solid pink;
  border-right: 40px solid transparent;
}

#rectangle {
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 50px;
  background: #f7f9fc;
}

.onlyPhone {
  visibility: hidden;
}

@media only screen and (max-width: 800px) {
  .whyApplatesContainer {
    background: #f7f9fc;
    /* background: #0be925; */
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    grid-template-areas:
      "title-container"
      "ScheduleAppointment"
      "GroupAppointment"
      "Notifications"
      "ControlSchedule"
      "BodyMeasurements"
      "SubCards"
      "HealthDeclaration"
      "WaitingList"
      "NotificationCenter"
      "Analytics"
      "CustomApp"
      "Accessibility";
  }

  .onlyPhone {
    visibility: visible;
  }
}
</style>