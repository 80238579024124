<script setup>
const props = defineProps({
  icon: String,
  name: String,
  content: String,
});

const { content, icon, name } = props;


</script>

<template>
  <div class="card">
    <div class="comment-content">
        <h3 class="title">{{ name }}</h3>
        <p class="comment">{{ content }}</p>
    </div>
    <div class="comment-img">
        <!-- <div class="comment-img-avatar" 
        style="backgroundImage: url('src/assets/wfc.jpg');"></div> -->
        <!-- <div class="comment-img-avatar"  :style="{'background-image': 'url(' + require(' + {{icon}} + ') + ')'}"></div> -->
        <!-- <div class="comment-img-avatar" v-bind:style="{ backgroundImage: 'url(' + icon + ')' }"></div> -->
        <div class="comment-img-avatar" :style="{ backgroundImage: `url(${require('@/assets/' + icon)})` }"></div> 

    </div>
  </div>
</template>

<style scoped>
.card {
  width: 100%;
  height: 90px;
  margin: 12px 0;
  display: flex;
  align-items: center;
}

.comment-img {
  width: 50px;
  height: 50px;
  margin-left: 16px;
}

.comment-img-avatar {
    width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background: rebeccapurple; */
   background-image: url("../../assets/wfc.jpg");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.comment-content {
  flex-grow: 1;
  height: 80px;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 12px 12px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  box-shadow: 2px 1px #c4c4c4;
}

.comment-content h3 {
    color: #2196f3;
    font-size: 14px;
    font-weight: 400;
}

.comment-content p {
    color: #616161;
    font-size: 12px;
    text-align: right;
}
</style>