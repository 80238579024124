<template>
  <div class="CustomersListContainer">
    <div class="businesses">
      <h3 class="name fitness">סטודיו לאימונים</h3>
      <h3 class="name barber">מספרות</h3>
      <h3 class="name beauty">סלוני יופי</h3>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.CustomersListContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.businesses {
  margin-right: 8px;
}

.name {
  color: #616161;
  font-weight: 300;
  padding: 12px 0;
  text-align: end;
  font-size: 1rem;
}

.fitness {
  animation: fitness-animation 10s linear forwards infinite;
}

.barber {
  animation: barber-animation 10s linear forwards infinite;
}

.beauty {
  animation: beauty-animation 10s linear forwards infinite;
}


@keyframes fitness-animation {
  0% {
    color: #2196f3;
    
  }
  25% {
    color: #2196f3;
    
  }
  30% {
   color: #616161;
    font-weight: 300;
  }
  31% {
   color: #616161;
    font-weight: 300;
  }
  32% {
    color: #616161;
    font-weight: 300;
  }
  33% {
    color: #616161;
    font-weight: 300;
  }
  85% {
    color: #616161;
    font-weight: 300;
  }
  90% {
    color: #2196f3;
    
  }
  100% {
   color: #2196f3;
    
  }
}

@keyframes barber-animation {
  0% {
    color: #616161;
    font-weight: 300;
  }
  25% {
    color: #616161;
    font-weight: 300;
  }
  30% {
    color: #2196f3;
    
  }
  55% {
    color: #2196f3;
    
  }
  60% {
   color: #616161;
    font-weight: 300;
  }
  61% {
   color: #616161;
    font-weight: 300;
  }
  62% {
    color: #616161;
    font-weight: 300;
  }
  63% {
    color: #616161;
    font-weight: 300;
  }
  100% {
    color: #616161;
    font-weight: 300;
  }
}

@keyframes beauty-animation {
  0% {
    color: #616161;
    font-weight: 300;
  }
  25% {
   color: #616161;
    font-weight: 300;
  }
  30% {
    color: #616161;
    font-weight: 300;
  }
  55% {
    color: #616161;
    font-weight: 300;
  }
  60% {
    color: #2196f3;
    
  }
  85% {
   color: #2196f3;
    
  }
  90% {
    color: #616161;
    font-weight: 300;
  }
  91% {
    color: #616161;
    font-weight: 300;
  }
  92% {
   color: #616161;
    font-weight: 300;
  }
  93% {
    color: #616161;
    font-weight: 300;
  }
  100% {
   color: #616161;
    font-weight: 300;
  }
}

</style>