<template>
  <div class="NotificationCenterContainer center">
   
    <div class="space"></div>
    <div class="img-content">
      <div class="device"></div>

      <div class="visible-content">
        <div class="notification-modal center">
          <div class="notification-content">
            <h3>התראה</h3>
            <h4>התור שלך נדחה ל 11/4 בשעה 12:30</h4>
          </div>
          <div class="notification-icon center">
            <i class="fa-solid fa-house fa-xs" style="color: #ffffff;"></i>
          </div>
        </div>
        <div class="notification-modal2 center">
          <div class="notification-content">
            <h3>התראה</h3>
            <h4>המנוי שלך מסתיים בעוד שבוע בתאריך 17/4</h4>
          </div>
          <div class="notification-icon center">
            <i class="fa-solid fa-house fa-xs" style="color: #ffffff;"></i>
          </div>
        </div>
      </div>
    </div>
     <div class="desc-content center">
      <h2 class="title">מרכז עדכונים</h2>
      <h2
        class="content"
      >רשימת התראות במטרה להשאיר את הלקוחות מעודכנים בזמני התורים, שינויים במועדים, רשימת המתנה, שינויים בכרטיסיות, תוקף המנויים ועוד</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications"
};
</script>

<style scoped>
.NotificationCenterContainer {
  height: 400px;
  
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/notification-center.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.space {
  width: 32px;
  height: 300px;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 0.9rem;
  max-width: 200px;
  text-align: center;
}

.visible-content {
  height: 100px;
  width: 200px;
  position: absolute;
  top: 0;
  margin-top: 75px;
  left: 0px;
  overflow: hidden;
}

.notification-modal {
  display: flex;
  width: 96%;
  height: 36px;
  margin-top: 100px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 1.5%;
  border-radius: 10px;
  animation: modal-animation 8s ease-in-out forwards infinite;
}

.notification-modal2 {
  display: flex;
  width: 96%;
  height: 36px;
  margin-top: 100px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 1.5%;
  border-radius: 10px;
  animation: modal-animation2 8s ease-in-out forwards infinite;
}

.notification-icon {
  width: 36px;
  height: 36px;
  background: #2196f3;
  border-radius: 0 10px 10px 0;
}

.notification-content {
  flex-grow: 1;
  height: 36px;
  padding: 4px 8px;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.notification-content h3 {
  color: #616161;
  font-size: 0.5rem;
}

.notification-content h4 {
  color: #616161;
  font-size: 0.45rem;
  text-align: end;
  font-weight: 400;
}

@keyframes modal-animation {
  0% {
    margin-top: 100px;
    opacity: 1;
  }
  10% {
    margin-top: 5px;
    opacity: 1;
  }
  80% {
    margin-top: 5px;
    opacity: 1;
  }
  84% {
    margin-top: 5px;
    opacity: 0;
  }
  85% {
    margin-top: 100px;
    opacity: 0;
  }
  100% {
    margin-top: 100px;
    opacity: 1;
  }
}

@keyframes modal-animation2 {
  0% {
    margin-top: 100px;
    opacity: 1;
  }
  15% {
    margin-top: 100px;
    opacity: 1;
  }
  30% {
    margin-top: 45px;
    opacity: 1;
  }
  80% {
    margin-top: 45px;
    opacity: 1;
  }
  84% {
    margin-top: 45px;
    opacity: 0;
  }
  85% {
    margin-top: 100px;
    opacity: 0;
  }
  100% {
    margin-top: 100px;
    opacity: 1;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}

@media only screen and (min-width: 801px) {
  .NotificationCenterContainer {
    direction: rtl;
  }

  .visible-content {
  direction: ltr;
}
}
</style>