<template>
  <v-app>
     <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style >
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>