<template>
  <div class="BodyMeasurementsContainer center">
    <div class="space"></div>
     <div class="img-content">
      <div class="device"></div>
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
      
    </div>
    <div class="desc-content center">
      <h2 class="title">מדידות גוף</h2>
      <h2 class="content">סטודיו לאימונים ינהל מדידות גוף ומעקב אחר התקדמות המתאמנים</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyMeasurements"
};
</script>

<style scoped>
.BodyMeasurementsContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/body-shape.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}


.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.line1 {
  width: 0px;
  height: 7px;
  margin-top: 155px;
  position: absolute;
  top: 0;
  left: 15px;
  opacity: 0;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: line1-animation 10s ease-in-out forwards infinite;
}

.line2 {
  width: 0px;
  height: 7px;
  margin-top: 145px;
  position: absolute;
  top: 0;
  left: 51px;
  opacity: 0;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: line2-animation 10s ease-in-out forwards infinite;
}

.line3 {
  width: 0px;
  height: 7px;
  margin-top: 235px;
  position: absolute;
  top: 0;
  left: 55px;
  opacity: 0;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: line3-animation 10s ease-in-out forwards infinite;
}

@keyframes line1-animation {
  0% {
    width: 0px;
    height: 7px;
    opacity: 0;
  }
  10% {
    width: 33px;
    height: 7px;
    opacity: .9;
  }
  80% {
    width: 33px;
    height: 7px;
    opacity: 0;
  }
  100% {
     width: 0px;
    height: 7px;
    opacity: 0;
  }
}



@keyframes line2-animation {
  0% {
    width: 0px;
    height: 7px;
    opacity: 0;
  }
  10% {
    width: 97px;
    height: 7px;
    opacity: .9;
  }
  80% {
    width: 97px;
    height: 7px;
    opacity: 0;
  }
  100% {
     width: 0px;
    height: 7px;
    opacity: 0;
  }
}


@keyframes line3-animation {
  0% {
    width: 0px;
    height: 7px;
    opacity: 0;
  }
  10% {
    width: 87px;
    height: 7px;
    opacity: .9;
  }
  80% {
    width: 87px;
    height: 7px;
    opacity: 0;
  }
  100% {
     width: 0px;
    height: 7px;
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}

@media only screen and (min-width: 801px) {
  .BodyMeasurementsContainer {
    direction: rtl;
  }
}
</style>