<template>
  <div class="DeviceSamplesMainContainer">
    <div id="clippedDiv" class="onlyPhone"></div>
    <div id="blueClippedDiv" class="onlyPhone"></div>
    <div id="pinkClippedDiv" class="onlyPhone"></div>
    <div class="phone-img animate"></div>
    <GoToMoreInfo />
  </div>
</template>

<script>
import Polygon from "../paint/Polygon";
import GoToMoreInfo from "../more-info/GoToMoreInfo.vue";
export default {
  name: "DeviceSamples",
  components: {
    Polygon,
    GoToMoreInfo,
  }
};
</script>

<style scoped>
.DeviceSamplesMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
  position: relative;
}

.phone-img {
  height: 350px;
  width: 300px;
  background-image: url("../../assets/two_devices.png");
  background-position: center;
  background-size: contain;
}


#clippedDiv {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 250px;
  background-color: #0a2540;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 60%, 100% 0%, 0 0%);
  clip-path: polygon(0% 0%, 0% 100%, 100% 60%, 100% 0%, 0 0%);
}

#blueClippedDiv {
  position: absolute;
  top: 149px;
  right: 0;
  width: 10%;
  height: 50px;
  background-color: #d2eafc;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 20%, 0% 100%, 100% 80%, 100% 0%, 0 20%);
}

#pinkClippedDiv {
  position: absolute;
  top: 170px;
  right: 0;
  width: 5%;
  height: 40px;
  background-color: pink;
  opacity: 0.7;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 16%, 0% 100%, 100% 84%, 100% 0%, 0 16%);
}

#leftBlueClippedDiv {
  position: absolute;
  top: 220px;
  left: 0;
  width: 10%;
  height: 50px;
  background-color: #d2eafc;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
  clip-path: polygon(0% 20%, 0% 100%, 100% 80%, 100% 0%, 0 20%);
}

.onlyPhone {
  visibility: hidden;
}

.animate {
  animation: shaking 8s linear infinite forwards;
}

@keyframes shaking {
  50% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 800px) {
  .onlyPhone {
    visibility: visible;
  }
}
</style>