<template>
  <div class="AnalyticsContainer center">
    <div class="desc-content center">
      <h2 class="title">פעילות וביצועים</h2>
      <h2 class="content">בקרת ביצועי העסק, סיכום פעילות, מעקב התקדמות ומגמות שיפור</h2>
    </div>
    <div class="img-content">
      <div class="device"></div>
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "Analytics"
};
</script>

<style scoped>
.AnalyticsContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/analytics.png");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.bar1 {
  width: 20px;
  height: 0px;
  margin-bottom: 35px;
  position: absolute;
  bottom: 0;
  left: 25px;
  opacity: 1;
  background: #2196f3;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #f5f5f5;
  animation: bar1-animation 10s ease-in-out forwards infinite;
}

.bar2 {
  width: 20px;
  height: 95px;
  margin-bottom: 35px;
  position: absolute;
  bottom: 0;
  left: 64px;
  opacity: 1;
  background: #2196f3;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #f5f5f5;
  animation: bar2-animation 10s ease-in-out forwards infinite;
}

.bar3 {
  width: 20px;
  height: 70px;
  margin-bottom: 35px;
  position: absolute;
  bottom: 0;
  left: 102px;
  opacity: 1;
  background: #2196f3;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #f5f5f5;
  animation: bar3-animation 10s ease-in-out forwards infinite;
}

.bar4 {
  width: 20px;
  height: 112px;
  margin-bottom: 35px;
  position: absolute;
  bottom: 0;
  left: 140px;
  opacity: 1;
  background: #2196f3;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #f5f5f5;
  animation: bar4-animation 10s ease-in-out forwards infinite;
}

.percentage {
  color: white;
  font-size: 0.6rem;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.customer {
  color: white;
  font-size: 0.5rem;
  font-weight: 400;
}

@keyframes bar1-animation {
  0% {
    height: 0px;
    opacity: 1;
  }
  30% {
    height: 80px;
  }
  70% {
    height: 80px;
  }
  80% {
    height: 80px;
    opacity: 1;
  }
  90% {
    height: 80px;
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes bar2-animation {
  0% {
    height: 0px;
    opacity: 1;
  }
  30% {
    height: 95px;
  }
  70% {
    height: 95px;
  }
  80% {
    height: 95px;
    opacity: 1;
  }
  90% {
    height: 95px;
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes bar3-animation {
  0% {
    height: 0px;
    opacity: 1;
  }
  30% {
    height: 70px;
  }
  70% {
    height: 70px;
  }
  80% {
    height: 70px;
    opacity: 1;
  }
  90% {
    height: 70px;
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes bar4-animation {
  0% {
    height: 0px;
    opacity: 1;
  }
  30% {
    height: 112px;
  }
  70% {
    height: 112px;
  }
  80% {
    height: 112px;
    opacity: 1;
  }
  90% {
    height: 112px;
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}
</style>