<template>
  <div class="mainContainerr">
    <div class="grid-item Slogan">
      <Slogan />
    </div>
    <div class="grid-item DeviceSamples">
      <DeviceSamples />
    </div>
    <div id="clippedDiv" class="onlyDesktop"></div>
  </div>
</template>
 
<script>
import DeviceSamples from "./DeviceSamples.vue";
import Slogan from "./Slogan.vue";

export default {
  name: "intro",

  components: {
    DeviceSamples,
    Slogan
  }
};
</script>

<style scoped>
.mainContainerr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "DeviceSamples Slogan";
  position: relative;
  background: white;
}

.grid-item {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Slogan {
  grid-area: Slogan;
  width: 100%;
  background: #0a2540;
}

.DeviceSamples {
  grid-area: DeviceSamples;
  width: 100%;
}

#clippedDiv {
  position: absolute;
  top: 90vh;
  width: 100%;
  height: 50vh;
  background-color: #0a2540;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 60%, 100% 0%, 0 0%);
  clip-path: polygon(0% 0%, 0% 100%, 100% 70%, 100% 0%, 0 0%);
}

.onlyDesktop {
  visibility: hidden;
}

@media only screen and (max-width: 800px) {
  .mainContainerr {
    grid-template-columns: 1fr;
    grid-template-areas:
      "Slogan"
      "DeviceSamples";
  }

  .grid-item {
    height: 400px;
  }

  .Slogan {
    grid-area: Slogan;
    height: 400px;
  }
}

@media only screen and (min-width: 801px) {

  .mainContainerr {
    background: #0a2540;
  }
  .onlyDesktop {
    visibility: visible;
  }

.grid-item {
     /* background: #0a2540; */
  /* background: greenyellow; */
  height: 100vh;
  }
   
}
</style>