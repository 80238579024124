<template>
  <div class="AnalyticsContainer center">
    <div class="space"></div>
    <div class="img-content">
      <div class="device"></div>
      <div class="custom-icon"></div>
      <div class="custom-icon2"></div>
    </div>

    <div class="desc-content center">
      <h2 class="title">אפלקציה ממותגת</h2>
      <h2 class="content">אפלקציה ממותגת עם השם, הלוגו, התמונות, הצבעים והעיצוב המתאים לעסק</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomApp"
};
</script>

<style scoped>
.AnalyticsContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/custom-app.png");
  background-position: bottom;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.custom-icon {
  height: 32px;
  width: 32px;
  background-image: url("../../assets/WeFit-custom-app.png");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.custom-icon2 {
  height: 32px;
  width: 32px;
  background-image: url("../../assets/WeFit-custom-app.png");
  background-position: top;
  padding-top: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
  position: absolute;
  top: 115px;
  right: 15px;
  animation: custom-icon2-animation 10s ease-in-out forwards infinite;
}

.percentage {
  color: white;
  font-size: 0.6rem;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.customer {
  color: white;
  font-size: 0.5rem;
  font-weight: 400;
}

@keyframes custom-icon2-animation {
  0% {
    width: 32px;
    height: 32px;
  }
  30% {
    width: 75px;
    height: 75px;
  }
  70% {
    width: 75px;
    height: 75px;
  }
  100% {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}
</style>