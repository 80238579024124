<template>
  <div class="AccessibilityContainer center">
    <div class="desc-content center">
      <h2 class="title">נגישות</h2>
      <h2 class="content">תמיכה בשפות שונות ללקוחות המגוונים, מענה למוגבלי שמיעה ודיבור</h2>
    </div>

    <div class="img-content">
      <div class="device"></div>
      <div class="current-lang-eng center">
        <h3 class="lang-txt">English</h3>
      </div>
      <div class="current-lang-heb center">
        <h3 class="lang-txt">עברית</h3>
      </div>
      <div class="current-lang-ara center">
        <h3 class="lang-txt">العربية</h3>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "Accessibility"
};
</script>

<style scoped>
.AccessibilityContainer {
  height: 400px;
}

.space {
  width: 32px;
  height: 300px;
}

.img-content {
  width: 200px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/select-language.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.lang-txt {
  color: white;
  font-size: 0.6rem;
}

.current-lang-eng {
  width: 160px;
  height: 30px;
  margin-top: 90px;
  position: absolute;
  top: 0;
  left: 20px;
  opacity: 1;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: current-lang-eng-animation 10s ease-in-out forwards infinite;
}

.current-lang-heb {
  width: 160px;
  height: 30px;
  margin-top: 125px;
  position: absolute;
  top: 0;
  left: 20px;
  opacity: 1;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: current-lang-heb-animation 10s ease-in-out forwards infinite;
}

.current-lang-ara {
  width: 160px;
  height: 30px;
  margin-top: 160px;
  position: absolute;
  top: 0;
  left: 20px;
  opacity: 0;
  background: #2196f3;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  animation: current-lang-ara-animation 10s ease-in-out forwards infinite;
}

@keyframes current-lang-eng-animation {
  0% {
    opacity: 1;
    margin-top: 90px;
  }
  15% {
    opacity: 1;
    margin-top: 90px;
  }
  25% {
    opacity: 1;
    margin-top: 125px;
  }
  26% {
    opacity: 0;
    margin-top: 125px;
  }
  40% {
  }
  50% {
  }
  65% {
  }
  75% {
  }
  99% {
    margin-top: 90px;
    opacity: 0;
  }
  100% {
    margin-top: 90px;
    opacity: 1;
  }
}

@keyframes current-lang-heb-animation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 1;
    margin-top: 125px;
  }
  40% {
    opacity: 1;
    margin-top: 125px;
  }
  50% {
    opacity: 1;
    margin-top: 160px;
  }
  51% {
    opacity: 0;
    margin-top: 160px;
  }
  65% {
  }
  75% {
    opacity: 0;
    margin-top: 125px;
  }
  76% {
    opacity: 1;
    margin-top: 125px;
  }
  90% {
    opacity: 1;
    margin-top: 125px;
  }
  99% {
    opacity: 1;
    margin-top: 90px;
  }
  100% {
    margin-top: 90px;
    opacity: 0;
  }
}

@keyframes current-lang-ara-animation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
    margin-top: 160px;
  }
  65% {
    opacity: 1;
    margin-top: 160px;
  }
  75% {
    opacity: 1;
    margin-top: 125px;
  }
  76% {
    opacity: 0;
  }
  90% {
    opacity: 0;
    margin-top: 125px;
  }
  100% {
    margin-top: 125px;
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .space {
    width: 10px;
    height: 300px;
  }
}

@media only screen and (min-width: 801px) {
  .AccessibilityContainer {
    direction: rtl;
  }
}
</style>