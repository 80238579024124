<template>
  <div id="clippedDiv"></div>
</template>

<script>
export default {
  name: "Polygon"
};

</script>

<style scoped>
#clippedDiv {
    position: absolute;
    top: 200px;
    width:100vw;
    height:25vh;
    background-color:red;
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
    clip-path: polygon(0% 0%, 0% 100%, 100% 50%, 100% 0%, 0 0%);
}
</style>