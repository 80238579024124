<template>
  <div class="NotificationsContainer center">
    <div class="desc-content center">
      <h2 class="title">עדכונים בזמן אמת</h2>
      <h2 class="content">הלקוחות יקבלו תזכורות לפני התור, ועדכונים עבור שינויים במועד התור</h2>
    </div>
    <div class="img-content center">
      <div class="device"></div>

      <div class="notification-modal center">
        <div class="notification-content">
          <h3>תזכורת</h3>
          <h4>נא לא לשכוח את התור שלך היום ב 12:00, נא להגיע בזמן</h4>
        </div>
        <div class="notification-icon center"></div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "Notifications"
};
</script>

<style scoped>
.NotificationsContainer {
  height: 400px;
}

.img-content {
  width: 240px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.desc-content {
  height: 300px;
  /* background: saddlebrown; */
  flex-grow: 1;
  flex-direction: column;
}

.device {
  height: 300px;
  width: 200px;
  background-image: url("../../assets/home-dark-mode.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px #f5f5f5;
}

.title {
  color: #2196f3;
  font-weight: 300;
  font-size: 1.1rem;
}

.content {
  padding: 8px;
  color: #616161;
  font-weight: 300;
  font-size: 1rem;
  max-width: 200px;
  text-align: center;
}

.notification-modal {
  display: flex;
  width: 184px;
  height: 48px;
  margin-bottom: 240px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  padding: 0 10px;
    animation: modal-animation 8s ease-in-out forwards infinite;
}

.notification-icon {
  width: 36px;
  height: 32px;
  background-image: url("../../assets/applates2_logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.notification-content {
  flex-grow: 1;
  height: 40px;
  padding: 0 8px;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.notification-content h3 {
  color: #616161;
  font-size: 0.6rem;
}

.notification-content h4 {
  color: #616161;
  font-size: 0.5rem;
  text-align: end;
  font-weight: 400;
}

@keyframes modal-animation {
  0% {
    margin-bottom: 300px;
  }
  10% {
    margin-bottom: 240px;
  }
  70% {
    margin-bottom: 240px;
  }
  80% {
    margin-bottom: 300px;
  }
  100% {
    margin-bottom: 300px;
  }
}
</style>