x<template>
  <div>
    <div class="NavigationBar">
      <NavigationBar />
    </div>
    <div class="mainContainer">
      <div class="grid-item Intro">
        <Intro />
      </div>
      <div class="grid-item Customers">
        <Customers />
      </div>
      <div class="grid-item Benefits">
        <Benefits />
      </div>
      <div class="grid-item WhyApplates">
        <WhyApplates />
      </div>
      <div class="grid-item CustomerSays">
        <CustomerSays />
      </div>
      <div class="grid-item Spacer"></div>
      <div class="grid-item MoreInfo">
        <MoreInfo />
      </div>
      <div class="grid-item CopyRight">
        <CopyRight />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Intro from "../components/intro/Intro.vue";
import WhyApplates from "../components/why-applates/WhyApplates.vue";
import Customers from "../components/customers/Customers.vue";
import MoreInfo from "../components/more-info/MoreInfo.vue";
import CopyRight from "../components/more-info/CopyRight.vue";
import Benefits from "../components/benefits/Benefits.vue";
import CustomerSays from "../components/customer-says/CustomerSays.vue";
import NavigationBar from "../components/NavigationBar/NavigationBar.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    NavigationBar,
    Intro,
    WhyApplates,
    Customers,
    MoreInfo,
    CopyRight,
    Benefits,
    CustomerSays
  }
  // mounted() {
  //   window.addEventListener("scroll", this.onScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.onScroll, true);
  // },
  // methods: {
  //   onScroll(e) {
  //     console.log(window.top.scrollY);
  //     this.windowTop = window.top.scrollY;
  //   }
  // }
});
</script>

<style scoped>
.mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 50px;
  grid-template-areas:
    "Intro Intro"
    "Benefits Customers"
    "WhyApplates WhyApplates"
    "Spacer Spacer"
    "MoreInfo CustomerSays"
    "CopyRight CopyRight";
}

.grid-item {
  height: 400px;
}

.NavigationBar {
  height: 60px;
  grid-area: NavigationBar;
}

.Intro {
  /* height: 400px; */
  height: 100vh;
  grid-area: Intro;
}

.Customers {
  height: 400px;
  grid-area: Customers;
}

.Benefits {
  height: 400px;
  grid-area: Benefits;
}

.WhyApplates {
  height: 2570px;
  /* height:  2170px; */
  /* title + rows + gap ; 50 + 400*5 + 30*4  */
  grid-area: WhyApplates;
}

.CustomerSays {
  height: 400px;
  grid-area: CustomerSays;
}

.MoreInfo {
  height: 400px;
  grid-area: MoreInfo;
}

.CopyRight {
  height: 70px;
  grid-area: CopyRight;
}

.Spacer {
  height: 70px;
  grid-area: Spacer;
}

@media only screen and (max-width: 800px) {
  .mainContainer {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    grid-template-areas:
      "Intro"
      "Customers"
      "Benefits"
      "WhyApplates"
      "CustomerSays"
      "MoreInfo"
      "Spacer"
      "CopyRight";
  }

  .WhyApplates {
    /* title + rows + gap ; 50 + 400*10 + 30*9 */
    height: 4320px;
    height: 5380px;
    grid-area: WhyApplates;
  }

  .Intro {
    height: 800px;
    grid-area: Intro;
  }

  .Spacer {
    height: 0px;
  }
}
</style>